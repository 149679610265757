import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'

import path from './pathSettings'
import Home from '../components/Page-Home'
import Login from '../components/Page-Login'
import Error404 from '../components/Page-Error404'
import Users from '../components/Page-Users'
import HealthRecords from '../components/Page-HealthRecords'

const RoutePath = () => {

    return (
        <Routes>
            <Route path={path('login')} element={<Login />} />
            <Route path={path('home')} element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path={path('users')} element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route path={path('healthRecords')} element={<PrivateRoute><HealthRecords /></PrivateRoute>} />
            <Route path={path('userHealthRecords', [':accountID'])} element={<PrivateRoute><HealthRecords /></PrivateRoute>} />
            <Route element={<Error404 />} />
        </Routes>
    )
}

export default RoutePath