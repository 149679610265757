const path = (page, payload) => {
  let pathTo = '/';
  switch (page) {
    case 'attendanceCheckpointSiteEdit':
      pathTo = `/attendance/checkpointsite/${payload?.[0]}/edit`;
      break;
    case 'login':
      pathTo = '/login';
      break;
    case 'users':
      pathTo = '/users';
      break;
    case 'healthRecords':
      pathTo = '/health-records';
      break;
    case 'userHealthRecords':
      pathTo = `/health-records/${payload?.[0]}`;
      break;
    case 'home':
      pathTo = '/';
      break;
    default:
  }
  return pathTo;
};

export default path;
