import React, { useContext, useState } from "react";
import { Button, Layout } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MenuOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { AppContext } from "../contexts/AppContext";
import MenuApp from "./Component-MenuApp";
import imgLogo from "../media/logo.png";
import { CollapseType } from "antd/es/layout/Sider";

const { Sider } = Layout;

// const withRouter = (Component) => {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   }

//   return ComponentWithRouterProp;
// };

const SideNav = (props: any): JSX.Element => {
  // console.log(props);
  // console.log(props.match.params);
  // const [mode, setMode] = props.match.params
  const { actionSetBroken, state: appContext } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed: boolean, type: CollapseType) => {
    // console.log(collapsed);
    // console.log(type);
    setCollapsed(collapsed);
  };

  if (!appContext?.validLogin) {
    return <></>;
  } else {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsible
        // zeroWidthTriggerStyle
        // trigger={
        //   <Button
        //     tabIndex={9}
        //     shape="circle"
        //     // style={{ background: "#000" }}
        //     onClick={() => setCollapsed((prev) => !prev)}
        //   >
        //     <MenuFoldOutlined />
        //   </Button>
        // }
        onBreakpoint={(broken) => {
          // console.log("BROKEN", broken);
          actionSetBroken(broken);
        }}
        onCollapse={(collapsed, type) => onCollapse(collapsed, type)}
        style={{
          minHeight: "100vh",
          zIndex: 1,
        }}
        collapsed={collapsed}
        zeroWidthTriggerStyle={
          collapsed === true
            ? // appState.collapsed === true ? (
              {
                position: "fixed",
                zIndex: 1,
                width: "25px",
                height: "30px",
                lineHeight: "30px",
                left: "0",
                top: "72px",
              }
            : {
                position: "fixed",
                zIndex: 1,
                width: "25px",
                height: "30px",
                lineHeight: "30px",
                left: "200px",
                top: "72px",
              }
        }
      >
        {collapsed === true ? (
          <div />
        ) : (
          <div className="logo">
            <img className="logoImg" alt='logo' src={imgLogo} />
          </div>
        )}
        <MenuApp
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          // location={props.location}
        />
      </Sider>
    );
  }
};

export default SideNav;
