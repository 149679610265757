import { useCallback, useContext, useEffect, useState } from "react";
import { Descriptions, Empty, notification, Spin, Table, Tag } from "antd";

import { AppContext } from "../contexts/AppContext";
import moment from "moment-timezone";
import { API } from "@aws-amplify/api";
import {
  listCognitoUsers,
  listHealthRecords,
} from "../backend/graphql/queries";

const Home = () => {
  const { actionSetPageTitle } = useContext(AppContext);
  const [values, setValues] = useState({
    sites: [0],
  });
  const [table, setTable] = useState({
    data: -1,
    loading: false,
  });
  const [tableUser, setTableUser] = useState({
    data: -1,
    loading: false,
  });
  const [displayList, setDisplayList] = useState(
    // default []
    // ["dummy data"]
    []
  );
  const [selectedDates, setSelectedDates] = useState([
    moment(moment().format("YYYY-MM-DDT00:00:00")).local().add(-1, "d"),
    moment(moment().format("YYYY-MM-DDT00:00:00")).local().add(1, "d"),
  ]);

  const getHealthRecords = useCallback(
    async (nextToken) => {
      setTable({
        ...table,
        loading: true,
      });

      try {
        let data = {};

        const variables = {
          filter: {
            date: {
              between: [
                selectedDates[0].format("YYYY-MM-01"),
                selectedDates[1].format("YYYY-MM-DDTHH:mm:ss"),
              ],
            },
          },
        };
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        // console.log("variables", variables);

        const result = await API.graphql({
          query: listHealthRecords,
          variables,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // console.log("result", result);
        data = result.data.result;

        // console.log("data", data);
        setTable({
          ...table,
          data: data.items,
          nextToken: data.nextToken,
        });
      } catch (error) {
        console.log("error:", error);
        notification.error({
          message: "Unable to retrieve deployment",
        });
        setTable({
          ...table,
          loading: false,
        });
      }
    },
    [table.nextToken]
  );

  const getCognitoUsersRecords = async (props, values) => {
    // console.log("values", values);
    setTable({
      ...table,
      loading: true,
    });

    try {
      let filters = [];

      if (Array.isArray(values)) {
        values.map((s) => {
          if (s.username) filters.push('username="' + s.username + '"');
          // return s;
        });
      }
      const variables = {};

      if (filters.length > 0) {
        variables.filter = filters.join(",");
      }
      // console.log("variables", variables);
      const result = await API.graphql({
        query: listCognitoUsers,
        variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // console.log("result", result);
      const data = JSON.parse(result.data.result).users;
      // console.log("data", data);
      setTableUser({
        ...tableUser,
        data: data.Users,
        // loading: data.Users.length === 0 ? false : true,
      });
    } catch (error) {
      console.log("error:", error);
      notification.error({
        message: "Unable to retrieve deployment",
      });
      setTableUser({
        ...tableUser,
        loading: false,
      });
    }
  };

  // const vc = useMemo(() => {
  //   return VList({
  //     height: 500,
  //     onReachEnd: table.nextToken ? getHealthRecords(table.nextToken) : null,
  //   });
  // }, [getHealthRecords]);

  useEffect(() => {
    actionSetPageTitle("Home - Dashboard");

    const currentDatetime = moment();
    let _values = {
      ...values,
      date: currentDatetime,
    };
    setValues(_values);
    getHealthRecords(table.pagination, _values);

    return () => {
      actionSetPageTitle("", false, "");
    };
  }, []);

  useEffect(() => {
    if (table.data !== -1) {
      let _congitoUserIds = table.data.map((s) => s.accountID);

      _congitoUserIds = [...new Set(_congitoUserIds)];
      getCognitoUsersRecords(
        null,
        _congitoUserIds.map((s) => ({ username: s }))
      );
    }
  }, [table.data]);

  useEffect(() => {
    if (table.data !== -1 && tableUser.data !== -1) {
      for (let data of table.data) {
        let user = tableUser.data?.find((s) => s.Username === data.accountID);
        let name = user?.Attributes.find((s) => s.Name === "name")?.Value ?? "";

        data.name = name;
      }

      setDisplayList(table.data);
      setTable({
        ...table,
        loading: false,
      });
    }
  }, [tableUser.data]);

  const columns = [
    {
      title: "accountID",
      dataIndex: "accountID",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 200,
      render: (value, _) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "SBP",
      dataIndex: "sbp",
      width: 100,
    },
    {
      title: "DBP",
      dataIndex: "dbp",
      width: 100,
    },
    {
      title: "Heart Rate",
      dataIndex: "heartrate",
      width: 100,
    },
    {
      title: "Height (cm)",
      dataIndex: "height",
      width: 100,
    },
    {
      title: "Weight (KG)",
      dataIndex: "weight",
      width: 100,
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      width: 200,
      render: (value, _) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "UpdatedOn",
      dataIndex: "updatedOn",
      width: 200,
      render: (value, _) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      width: 100,
    },
    {
      title: "DeletedOn",
      dataIndex: "deletedOn",
      width: 200,
      render: (value, _) => {
        return (
          value && moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss")
        );
      },
    },
  ];

  return (
    <div className="container-content allow-overflow">
      <div className="content-content">
        <Descriptions
          title={
            <div>
              <span>{"Latest Records "}</span>
              <Tag>{selectedDates[0].format("DD MMM YYYY, HH:mm")}</Tag>
              <span> - </span>
              <Tag>{selectedDates[1].format("DD MMM YYYY, HH:mm")}</Tag>
            </div>
          }
        />
      </div>
      <Spin spinning={table.loading}>
        <div className="content-content">
          {displayList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={displayList}
              pagination={false}
              loading={table.loading}
              scroll={{ y: 500 }}
              rowClassName={"table-row-clickable"}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Home;
