import { Line } from "@ant-design/charts";
import { UploadOutlined } from '@ant-design/icons';
import { API } from "@aws-amplify/api";
import { Button, Descriptions, Empty, Spin, Table, Tag, Upload, notification } from "antd";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { VList } from "virtuallist-antd";
import {
  createHealthRecord
} from "../backend/graphql/mutations";
import {
  listHealthRecords,
  listHealthRecordsByDate,
} from "../backend/graphql/queries";
import { AppContext } from "../contexts/AppContext";
import combineQuery from 'graphql-combine-query'
import gql from 'graphql-tag'
const { print } = require('graphql/language/printer');

const HealthRecords = () => {
  const { state: appContext, actionSetPageTitle } = useContext(AppContext);
  const navigate = useNavigate();
  const { accountID } = useParams();
  const { state } = useLocation();
  const { user, viewChart } = state || { user: null, viewChart: false };
  const [values, setValues] = useState({
    sites: [0],
  });
  const [table, setTable] = useState({
    data: -1,
    loading: false,
    nextToken: null,
  });
  const [chartData, setChartData] = useState([]);
  const [displayList, setDisplayList] = useState(
    // default []
    // ["dummy data"]
    []
  );
  const [uploadedObj, setUploadedObj] = useState()

  const getHealthRecords = useCallback(async (nextToken, values) => {
    // console.log("values", values);
    setTable({
      ...table,
      loading: true,
    });

    try {
      let data = {};

      // filter by accountID
      if (accountID) {
        const variables = {
          accountID: accountID,
          sortDirection: "DESC",
        };
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        // console.log("variables", variables);

        const result = await API.graphql({
          query: listHealthRecordsByDate,
          variables,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // console.log("result", result);
        data = result.data.result;
      } else {
        const variables = {
          // filter: filter,
        };
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        // console.log("variables", variables);

        const result = await API.graphql({
          query: listHealthRecords,
          variables,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // console.log("result", result);
        data = result.data.result;
      }
      // console.log("data", data);
      setTable({
        ...table,
        data: nextToken ? table.data.concat(data.items) : data.items,
        loading: data.items.length === 0 ? false : true,
        nextToken: data.nextToken,
      });
    } catch (error) {
      console.log("error:", error);
      notification.error({
        message: "Unable to retrieve deployment",
      });
      setTable({
        ...table,
        loading: false,
      });
    }
  }, [table.nextToken]);

  useEffect(() => {
    actionSetPageTitle("Health Records");

    const currentDatetime = moment();
    let _values = {
      ...values,
      date: currentDatetime,
    };
    setValues(_values);
    getHealthRecords(table.pagination, _values);

    return () => {
      actionSetPageTitle("", false, "");
    };
  }, []);

  useEffect(() => {
    if (table.data !== -1) {
      let _chartData = [];
      for (let data of table.data) {
        let lines = ["sbp", "dbp", "heartrate", "height", "weight"];
        for (let line of lines) {
          _chartData.push({
            type: line,
            value: data[line],
            date: moment.utc(data.date).local().format("YYYY/MM/DD"),
            deleted: data["_deleted"] || data["deleted"],
          });
        }
      }

      // console.log(table.data);
      setDisplayList(table.data.sort((a, b) => (a.date > b.date ? -1 : 1)));
      setChartData(_chartData);
      setTable({
        ...table,
        loading: false,
      });
    }
  }, [table.data]);

  async function createHealthRecords(data) {
    // console.log('data', data)
    const { document, variables } = combineQuery('CreateHealthRecord')
      .addN(gql`${createHealthRecord}`, data.map(item => ({ input: item })))
    // console.log('query', print(document), variables)

    const result = await API.graphql({
      query: print(document),
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    // console.log("result", result);
    setUploadedObj(null);
  }

  useEffect(() => {
    if (uploadedObj) {
      let keys = ["sbp", "dbp", "heartrate", "height", "weight", "id", "accountID", "date", "createdOn", "updatedOn", "deleted", "deletedOn"];
      let data = uploadedObj
        .filter(item => keys.every(key => item.hasOwnProperty(key)))
        .map(item => {
          return keys.reduce((acc, key) => {
            acc[key] = item[key];
            return acc;
          }, {});
        });

      createHealthRecords(data);
    }
  }, [uploadedObj])

  const columns = [
    {
      title: "accountID",
      dataIndex: "accountID",
      width: 150,
      render: (value) => {
        return accountID ? user?.name : value;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 200,
      render: (value, _, index) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "id",
      dataIndex: "id",
      width: 300,
    },
    {
      title: "SBP",
      dataIndex: "sbp",
      width: 100,
    },
    {
      title: "DBP",
      dataIndex: "dbp",
      width: 100,
    },
    {
      title: "Heart Rate",
      dataIndex: "heartrate",
      width: 100,
    },
    {
      title: "Height (cm)",
      dataIndex: "height",
      width: 100,
    },
    {
      title: "Weight (KG)",
      dataIndex: "weight",
      width: 100,
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      width: 200,
      render: (value, _, index) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "UpdatedOn",
      dataIndex: "updatedOn",
      width: 200,
      render: (value, _, index) => {
        return moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss");
      },
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      width: 100,
    },
    {
      title: "DeletedOn",
      dataIndex: "deletedOn",
      width: 200,
      render: (value, _, index) => {
        return (
          value && moment.utc(value).local().format("DD MMM YYYY, HH:mm:ss")
        );
      },
    },
  ];

  const handleTableChange = (paginate) => {
    getHealthRecords(
      {
        //   ...table.pagination,
        //   current: paginate.current,
        //   pageSize: paginate.pageSize,
        //   offset: paginate.current * paginate.pageSize - paginate.pageSize,
      },
      values
    );
  };

  function handleUpload(item) {
    var reader = new FileReader();
    reader.onload = (event) => {
      console.log(event.target.result);
      var obj = JSON.parse(event.target.result);
      setUploadedObj(obj);
    };
    reader.readAsText(item.file.originFileObj);
  }

  const vc = useMemo(() => {
    return VList({
      height: 500,
      onReachEnd: table.nextToken?.length
        ? getHealthRecords(table.nextToken)
        : null,
    });
  }, [getHealthRecords, table.nextToken]);

  const config = {
    data: chartData
      .filter((s) => !s.deleted)
      .sort((a, b) => (a.date > b.date ? 1 : -1)),
    xField: "date",
    yField: "value",
    seriesField: "type",
    xAxis: {
      title: {
        text: "Date",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
        style: {
          fontSize: 16,
        },
      },
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ({ type }) => {
      switch (type) {
        case "sbp":
          return "#F4664A";
        case "dbp":
          return "#30BF78";
        case "heartrate":
          return "#FAAD14";
        default:
          return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      }
    },
    lineStyle: ({ type }) => {
      return {
        opacity: 1,
      };
    },
  };

  return (
    <div className="container-content allow-overflow">
      <div className="content-content">
        <Descriptions
          title={
            <div>
              <span></span>
              <Tag>Health Records</Tag>
              {accountID ? <Tag>{user?.name}</Tag> : <></>}
            </div>
          }
          extra={<Upload
            // onPreview={handlePreview}
            action={() => { }}
            onChange={handleUpload}
          >
            <Button icon={<UploadOutlined />}>Import</Button>
          </Upload>}
        />
      </div>
      <Spin spinning={table.loading}>
        <div className="content-content">
          {viewChart && displayList.length ? (
            <Line style={{ margin: 8 }} {...config} />
          ) : (
            <></>
          )}
          {displayList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={displayList}
              pagination={false}
              loading={table.loading}
              scroll={{ y: 500 }}
              onChange={handleTableChange}
              components={vc}
              rowClassName={"table-row-clickable"}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default HealthRecords;
