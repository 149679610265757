import { useEffect, useState } from "react";
import { Menu, MenuProps } from "antd";
import {
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MdOutlineFileCopy } from "react-icons/md";
import { useParams, useLocation, Link } from "react-router-dom";

import path from "../utils/pathSettings";

const MenuApp = (props: any) => {
  const { collapsed, setCollapsed } = props;
  const location = useLocation();

  const getPathSubMenuKey = (path: string) => {
    const array = path?.split("/") ?? [];
    let subMenuKey = "/";

    for (let i = 0; i < array.length; i++) {
      if (array[i].length !== 0) {
        subMenuKey += array[i];
        break;
      }
    }

    return subMenuKey;
  };
  const [selectedKeys, setSelectedKeys] = useState(location?.pathname);
  const [openKeys, setOpenKeys] = useState(
    getPathSubMenuKey(location?.pathname)
  );

  useEffect(() => {
    // console.log("location", location, props, params);
    setSelectedKeys(location?.pathname);
    setOpenKeys(getPathSubMenuKey(location?.pathname));
  }, [location]);

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      setCollapsed(true);
    }
  };

  const contentMenu: MenuProps["items"] = [
    {
      key: path("home"),
      icon: <HomeOutlined />,
      label: <Link to={path("home")}>Home</Link>,
      // label: 'Home'
    },
    {
      key: path("users"),
      icon: <UserOutlined />,
      label: <Link to={path("users")}>Users</Link>,
      // label: 'Users',
    },
    {
      key: path("healthRecords"),
      icon: <MdOutlineFileCopy />,
      label: <Link to={path("healthRecords")}>Health Records</Link>,
      // label: 'Health Records',
    },
  ];

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[selectedKeys]}
      defaultOpenKeys={[openKeys]}
      selectedKeys={[selectedKeys]}
      style={
        collapsed === true
          ? {
              // position: "fixed",
              // top: "64px",
              // // width: "50px",
              // // height: "",
              // overflow: "auto",
            }
          : {
              position: "fixed",
              top: "64px",
              width: "200px",
              height: "90vh",
              overflow: "auto",
            }
      }
      onClick={handleClick}
      className="scrollbar"
      items={contentMenu}
    />
  );
};

export default MenuApp;
