import React, { useContext } from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

import { AppContext } from "../contexts/AppContext";

const PrivateRoute = ({ children }: RouteProps): any => {
  const { state: appContext } = useContext(AppContext);
  return appContext?.validLogin ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
      // state={{
      //   from: rest.location,
      // }}
    />
  );
};

export { PrivateRoute };
