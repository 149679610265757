import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Descriptions,
  Empty,
  notification,
  Spin,
  Table,
  Tag,
} from "antd";
// import FilterDashboard from './Component-Filter-Dashboard'
// import FilterAppliedDashboard from './Component-FilterApplied-Dashboard'
// import CardSiteDashboard from './Component-Card-Site-Dashboard'

import { AppContext } from "../contexts/AppContext";
import moment from "moment-timezone";
import { API } from "@aws-amplify/api";
import { listCognitoUsers } from "../backend/graphql/queries";
import path from "../utils/pathSettings";

const Users = () => {
  const { state: appContext, actionSetPageTitle } = useContext(AppContext);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    sites: [0],
  });
  const [table, setTable] = useState({
    data: -1,
    loading: false,
  });
  const [tableAttendance, setTableAttendance] = useState({
    data: -1,
    loading: false,
  });
  const [displayList, setDisplayList] = useState(
    // default []
    // ["dummy data"]
    []
  );

  const getCognitoUsersRecords = async (props, values) => {
    // console.log("values", values);
    setTable({
      ...table,
      loading: true,
    });

    try {
      // let filter = {}

      // if (values.sites && values.sites.length > 0 && (values.sites.length == 1 && values.sites[0] !== 0)) {
      //     filter = {
      //         ...filter,
      //         securitySiteID: { eq: values.site }
      //     }
      // }

      const variables = {
        // filter: filter,
      };
      // console.log("variables", variables);
      const result = await API.graphql({
        query: listCognitoUsers,
        variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // console.log("result", result);
      const data = JSON.parse(result.data.result).users;
      // console.log("data", data);
      setTable({
        ...table,
        data: data.Users,
        loading: data.Users.length === 0 ? false : true,
        // pagination: {
        //     ...table.pagination,
        //     current: props.current,
        //     offset: props.offset,
        //     pageSize: props.pageSize,
        //     total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
        // }
      });
    } catch (error) {
      console.log("error:", error);
      notification.error({
        message: "Unable to retrieve deployment",
      });
      setTable({
        ...table,
        loading: false,
      });
    }
  };

  useEffect(() => {
    actionSetPageTitle("Users");

    const currentDatetime = moment();
    let _values = {
      ...values,
      date: currentDatetime,
    };
    setValues(_values);
    getCognitoUsersRecords(table.pagination, _values);
    // getAttendanceRecords(null, _values)

    return () => {
      actionSetPageTitle("", false, "");
    };
  }, []);

  useEffect(() => {
    if (table.data !== -1) {
      for (let data of table.data) {
        // console.log('parseData', data);
        data.id = data.Username;
        data.name = data.Attributes.find((s) => s.Name === "name")?.Value ?? "";
        data.email =
          data.Attributes.find((s) => s.Name === "email")?.Value ?? "";
        data.email_verified =
          data.Attributes.find((s) => s.Name === "email_verified")?.Value ?? "";
      }

      // console.log(table.data);
      setDisplayList(table.data);
      setTable({
        ...table,
        loading: false,
      });
    }
  }, [table.data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "accountID",
      dataIndex: "Username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Email Verified",
      dataIndex: "email_verified",
    },
    {
      title: "User Status",
      dataIndex: "UserStatus",
    },
    {
      title: "",
      dataIndex: "action",
      render: (value, record, index) => {
        // console.log(value, record, index);
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigate(path("userHealthRecords", [record.Username]), {
                state: { user: record, viewChart: true },
              });
            }}
          >
            View Chart
          </Button>
        );
      },
    },
  ];

  const handleTableChange = (paginate) => {
    getCognitoUsersRecords(
      {
        //   ...table.pagination,
        //   current: paginate.current,
        //   pageSize: paginate.pageSize,
        //   offset: paginate.current * paginate.pageSize - paginate.pageSize,
      },
      values
    );
  };

  const handleTableRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // console.log("rowClicked", record);
        navigate(path("userHealthRecords", [record.Username]), {
          state: { user: record },
        });
      }, // click row
      onDoubleClick: (event) => {}, // double click row
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };

  return (
    <div className="container-content allow-overflow">
      <div className="content-content">
        <Descriptions
          title={
            <div>
              <span></span>
              <Tag>Users</Tag>
            </div>
          }
        />
      </div>
      <Spin spinning={table.loading}>
        <div className="content-content">
          {displayList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={displayList}
              pagination={table.pagination}
              loading={table.loading}
              onChange={handleTableChange}
              onRow={handleTableRow}
              rowClassName={"table-row-clickable"}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Users;
