// a, c, u, d


/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkAuthenticationAttribute = /* GraphQL */ `
  mutation CheckAuthenticationAttribute(
    $phoneNo: String
    $email: String!
    $preferredUsername: String
  ) {
    result: checkAuthenticationAttribute(
      phoneNo: $phoneNo
      email: $email
      preferredUsername: $preferredUsername
    )
  }
`;
export const adminChangePassword = /* GraphQL */ `
  mutation AdminChangePassword(
    $username: String!
    $password: String!
    $random: Int
    $permanent: Int
    $forceSignOut: Int
  ) {
    result: adminChangePassword(
      username: $username
      password: $password
      random: $random
      permanent: $permanent
      forceSignOut: $forceSignOut
    )
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $username: String!
    $attributes: String
    $forceSignOut: Int
    $accountStatus: String
  ) {
    result: updateCognitoUser(
      username: $username
      attributes: $attributes
      forceSignOut: $forceSignOut
      accountStatus: $accountStatus
    )
  }
`;
export const createHealthRecord = /* GraphQL */ `
  mutation CreateHealthRecord(
    $input: CreateHealthRecordInput!
    $condition: ModelHealthRecordConditionInput
  ) {
    result: createHealthRecord(input: $input, condition: $condition) {
      id
      accountID
      date
      name
      heartrate
      sbp
      dbp
      height
      weight
      createdOn
      updatedOn
      deleted
      deletedOn
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHealthRecord = /* GraphQL */ `
  mutation UpdateHealthRecord(
    $input: UpdateHealthRecordInput!
    $condition: ModelHealthRecordConditionInput
  ) {
    result: updateHealthRecord(input: $input, condition: $condition) {
      id
      accountID
      date
      name
      heartrate
      sbp
      dbp
      height
      weight
      createdOn
      updatedOn
      deleted
      deletedOn
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHealthRecord = /* GraphQL */ `
  mutation DeleteHealthRecord(
    $input: DeleteHealthRecordInput!
    $condition: ModelHealthRecordConditionInput
  ) {
    result: deleteHealthRecord(input: $input, condition: $condition) {
      id
      accountID
      date
      name
      heartrate
      sbp
      dbp
      height
      weight
      createdOn
      updatedOn
      deleted
      deletedOn
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
