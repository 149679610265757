import React, { useContext } from "react";
import logo from "./logo.svg";
import "./css/App.css";
import "antd/dist/reset.css";
import {
  AlertOutlined,
  AuditOutlined,
  CalendarOutlined,
  CarOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  CompassOutlined,
  ContactsOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  FileExclamationOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  HomeOutlined,
  LoginOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  ShakeOutlined,
  SolutionOutlined,
  TrophyOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Layout, MenuProps, notification, Tag } from "antd";
import SideNav from "./components/Component-SideNav";
import HeaderApp from "./components/Component-HeaderApp";
import RoutePath from "./utils/RoutePath";
import { PageContainer, ProLayout } from "@ant-design/pro-components";
import { AppContext } from "./contexts/AppContext";
import path from "./utils/pathSettings";
import { Auth } from "aws-amplify";
import RouteProps from "./utils/RouteProps";
import Users from "./components/Page-Users";
import Home from "./components/Page-Home";

const { Content } = Layout;

const App = (): JSX.Element => {
  const { state: appContext, actionLogout } = useContext(AppContext);
  // const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("health_cognitouser") || "{}");
  const name = user?.signInUserSession?.idToken?.payload?.name ?? "";

  const contentMenu: MenuProps["items"] = [
    {
      key: path("profile"),
      label: (
        <Link to={path("profile")}>
          <b>{name}</b>
        </Link>
      ),
    },
    {
      key: path("settings"),
      label: <Link to={path("settings")}>Settings</Link>,
    },
    {
      key: "/logout",
      label: <div onClick={(e) => handleClick(e, "logout")}>Log Out</div>,
    },
  ];

  const handleClick = async (e: any, id: String) => {
    switch (id) {
      case "logout":
        await Auth.signOut();
        actionLogout();
        notification.success({
          message: "Log out successfully",
        });
        return;
      default:
    }
  };

  return (
    <BrowserRouter>
      <Layout
        className="default-theme"
        style={{ height: "100%", minHeight: "100vh" }}
      >
        <SideNav />
        <Layout>
          <HeaderApp />
          <Content className="content">
            <RoutePath />
          </Content>
        </Layout>
        {/* <ProLayout
          // {...RouteProps}

          route={{
            routes: [
              {
                key: path("home"),
                path: path("home"),
                name: "Home",
                icon: <HomeOutlined />,
                // indexRoute: {
                //   component: 'ConfigProduct/index',
                // },
              },
              {
                path: path("users"),
                name: "Users",
                icon: <UserOutlined />,
                // component: "./components/Page-Users",
              },
              {
                path: "/config",
                name: "配置中心",
                routes: [
                  {
                    path: "product",
                    name: "产品配置",
                    indexRoute: {
                      component: "ConfigProduct/index",
                    },
                    routes: [
                      {
                        path: "new",
                        component: "ConfigProduct/NewConfig",
                      },
                      {
                        path: "edit/:productKey",
                        component: "ConfigProduct/NewConfig",
                      },
                      {
                        path: "detail/:productKey",
                        component: "ConfigProduct/Detail",
                      },
                    ],
                  },
                  {
                    path: "productManage",
                    name: "产品管理",
                    indexRoute: {
                      component: "ConfigProductAll/index",
                    },
                    routes: [
                      {
                        path: "detail/:productKey",
                        component: "ConfigProductAll/Detail",
                      },
                    ],
                  },
                  {
                    path: "template",
                    name: "产品模板管理",
                    indexRoute: {
                      component: "ConfigTemplate/index",
                    },
                    routes: [
                      {
                        path: "new",
                        component: "ConfigTemplate/NewConfig",
                      },
                      {
                        path: "edit/:templateKey",
                        component: "ConfigTemplate/NewConfig",
                      },
                      {
                        path: "detail/:templateKey",
                        component: "ConfigTemplate/Detail",
                      },
                    ],
                  },
                  {
                    path: "configItem",
                    name: "配置项模板管理",
                    indexRoute: {
                      component: "ConfigItem/index",
                    },
                    routes: [
                      {
                        path: "new",
                        component: "ConfigItem/NewConfig",
                      },
                      {
                        path: "edit/:productKey",
                        component: "ConfigItem/NewConfig",
                      },
                      {
                        path: "detail/:productKey",
                        component: "ConfigItem/Detail",
                      },
                    ],
                  },
                  {
                    path: "meta",
                    name: "元数据管理",
                    component: "ConfigMeta",
                  },
                ],
              },
              {
                path: "asset",
                name: "资产",
                routes: [
                  {
                    path: "query",
                    name: "资产查询",
                    component: "Asset",
                  },
                  {
                    path: "collateral",
                    name: "抵押查询",
                    component: "Collateral",
                  },
                ],
              },
              {
                path: "bill",
                name: "账单",
                routes: [
                  {
                    path: "billNo",
                    name: "账单编号",
                    component: "BillNo",
                  },
                  {
                    path: "bill",
                    name: "账单查询",
                    component: "Bill",
                  },
                  {
                    path: "billItem",
                    name: "账单条目",
                    component: "BillItem",
                  },
                ],
              },
            ],
          }}
          // menu={{
          //   hideMenuWhenCollapsed: true,
          // }}
          onMenuHeaderClick={(e) => console.log(e)}
          menuItemRender={(item, dom) => (
            <div
              onClick={() => {
                // setPathname(item.path || "/welcome");
              }}
            >
              {dom}
            </div>
          )}
        >
          <PageContainer
            header={{
              // onBack: () =>
              //   appContext.backButton === true
              //     ? appContext.backURL !== ""
              //       ? () => navigate(appContext.backURL)
              //       : () => window.history.back()
              //     : false,
              title: appContext.pageTitle,
              subTitle: appContext.pageSubtitle,
              extra: [
                <Dropdown
                  menu={{ items: contentMenu }}
                  trigger={["click"]}
                  key={"profilepic"}
                >
                  <Avatar className="avatar" icon={<UserOutlined />} src={""} />
                </Dropdown>,
              ],
              backIcon: appContext?.backButton,
              // tags: [<Tag>Hello</Tag>]
            }}
            // content="content"
          >
            <Content className="content">
              <RoutePath />
            </Content>
          </PageContainer>
        </ProLayout> */}
      </Layout>
    </BrowserRouter>
  );
};

export default App;
