import React, { useContext, useEffect } from "react";
import { Avatar, Dropdown, Layout, Menu, notification, MenuProps } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

import path from "../utils/pathSettings";
import { AppContext } from "../contexts/AppContext";
import { PageHeader } from "@ant-design/pro-components";

const { Header } = Layout;

const HeaderApp = (): JSX.Element => {
  const { state: appContext, actionLogout } = useContext(AppContext);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("health_cognitouser") || "{}");
  const name = user?.signInUserSession?.idToken?.payload?.name ?? "";

  const content = (
    <Menu style={{ maxWidth: "250px" }}>
      <Menu.Item key={path("profile")}>
        <Link to={path("profile")}>
          <b>{name}</b>
        </Link>
      </Menu.Item>
      {/* <div style={{ padding: "5px 12px", fontWeight: "bold" }}>{"Name of User"}</div> */}
      <Menu.Divider />
      <Menu.Item key={path("settings")}>
        <Link to={path("settings")}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="/logout" onClick={(e) => handleClick(e, "logout")}>
        Log Out
      </Menu.Item>
    </Menu>
  );
  const contentMenu: MenuProps["items"] = [
    {
      key: path("profile"),
      label: (
        <Link to={path("profile")}>
          <b>{name}</b>
        </Link>
      ),
    },
    {
      key: path("settings"),
      label: <Link to={path("settings")}>Settings</Link>,
    },
    {
      key: "/logout",
      label: <div onClick={(e) => handleClick(e, "logout")}>Log Out</div>,
    },
  ];

  useEffect(() => {
    // console.log("appState: ", appState);
  }, []);

  const handleClick = async (e: any, id: String) => {
    switch (id) {
      case "logout":
        await Auth.signOut();
        actionLogout();
        notification.success({
          message: "Log out successfully",
        });
        return;
      default:
    }
  };

  if (!appContext?.validLogin) {
    return <></>;
  } else {
    return (
      <Header className="header">
        {/* {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )} */}
        <PageHeader
          onBack={() =>
            appContext.backButton === true
              ? appContext.backURL !== ""
                ? () => navigate(appContext.backURL)
                : () => window.history.back()
              : false
          }
          title={appContext.pageTitle}
          subTitle={appContext.pageSubtitle}
          extra={[
            <Dropdown
              menu={{ items: contentMenu }}
              trigger={["click"]}
              key={"profilepic"}
            >
              <Avatar className="avatar" icon={<UserOutlined />} src={""} />
            </Dropdown>,
          ]}
          backIcon={appContext?.backButton}
          // avatar={{ icon: <UserOutlined /> }}
        />
      </Header>
    );
  }
};

export default HeaderApp;
