import React, { createContext, useReducer, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const AppContext = createContext();

const checkLoginUser = () => {
  return (
    localStorage.getItem('health_cognitouser') !== null ? (true) : (false)
  )
}

let initialState = {
  broken: false,
  backButton: false,
  backURL: '',
  pageTitle: '',
  pageSubtitle: '',
  cognitoUser: null,
  validLogin: checkLoginUser(),
  loaded: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BROKEN':
      return { ...state, broken: action.payload, }
    case 'SET_BACKBUTTON':
      return { ...state, backButton: action.payload, }
    case 'SET_BACKURL':
      return { ...state, backURL: action.payload, }
    case 'SET_PAGETITLE':
      return { ...state, pageTitle: action.payload }
    case 'SET_PAGESUBTITLE':
      return { ...state, pageSubtitle: action.payload, }
    case 'SET_VALIDLOGIN':
      return { ...state, validLogin: action.payload };
    case 'SET_COGNITOUSER':
      return { ...state, cognitoUser: action.payload };
    case 'REMOVE_COGNITOUSER':
      return { ...state, cognitoUser: null };
    case 'SET_LOADED':
      return { ...state, loaded: action.payload };
    default:
      return state;
  }
};

const AppContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initCognitoUser = async () => {
      try {
        const value = localStorage.getItem('health_cognitouser');
        if (value) {
          dispatch({ type: 'SET_COGNITOUSER', payload: JSON.parse(value) });
          dispatch({ type: 'SET_VALIDLOGIN', payload: true });
        }
      } catch (e) {
        // console.log("Get sign in user error (AppContext): ", e);
      } finally {
        dispatch({ type: 'SET_LOADED', payload: true });
      }
    };

    initCognitoUser();

    return () => { };
  }, []);

  useEffect(() => {
    // console.log("APP STATE", state);
  }, [state]);

  // Actions
  const actionLogin = async user => {
    localStorage.setItem('health_cognitouser', JSON.stringify(user));
    dispatch({ type: 'SET_VALIDLOGIN', payload: true });
    dispatch({ type: 'SET_COGNITOUSER', payload: user });
  };

  const actionLogout = async () => {
    await Auth.signOut();
    dispatch({ type: 'SET_VALIDLOGIN', payload: false });
    dispatch({ type: 'SET_COGNITOUSER', payload: {} });
    localStorage.removeItem('health_cognitouser');
  };

  const actionSetBroken = (payload) => {
    dispatch({ type: 'SET_BROKEN', payload: payload })
  }

  const actionSetPageTitle = (title, boolBackBtn, backURL) => {
    dispatch({ type: 'SET_PAGETITLE', payload: title })
    if (boolBackBtn !== undefined)
      dispatch({ type: 'SET_BACKBUTTON', payload: boolBackBtn })
    if (backURL !== undefined)
      dispatch({ type: 'SET_BACKURL', payload: backURL })
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        actionLogin,
        actionLogout,
        actionSetBroken,
        actionSetPageTitle,
      }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
