// l, g, s


/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers($filter: String, $limit: Int, $pagination: String) {
    result: listCognitoUsers(filter: $filter, limit: $limit, pagination: $pagination)
  }
`;
export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($username: String!) {
    result: getCognitoUser(username: $username)
  }
`;
export const getHealthRecord = /* GraphQL */ `
  query GetHealthRecord($id: ID!, $date: String!) {
    result: getHealthRecord(id: $id, date: $date) {
      id
      accountID
      date
      name
      heartrate
      sbp
      dbp
      height
      weight
      createdOn
      updatedOn
      deleted
      deletedOn
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHealthRecords = /* GraphQL */ `
  query ListHealthRecords(
    $id: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelHealthRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    result: listHealthRecords(
      id: $id
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountID
        date
        name
        heartrate
        sbp
        dbp
        height
        weight
        createdOn
        updatedOn
        deleted
        deletedOn
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHealthRecords = /* GraphQL */ `
  query SyncHealthRecords(
    $filter: ModelHealthRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    result: syncHealthRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        date
        name
        heartrate
        sbp
        dbp
        height
        weight
        createdOn
        updatedOn
        deleted
        deletedOn
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listHealthRecordsByDate = /* GraphQL */ `
  query ListHealthRecordsByDate(
    $accountID: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHealthRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    result: listHealthRecordsByDate(
      accountID: $accountID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        date
        name
        heartrate
        sbp
        dbp
        height
        weight
        createdOn
        updatedOn
        deleted
        deletedOn
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
